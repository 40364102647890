import firebase from "firebase/app";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDUQ0ghQxyxOBZZvuRTbLfSWp1gnawsodg",
  authDomain: "invoiceme-ace6b.firebaseapp.com",
  databaseURL: "https://invoiceme-ace6b.firebaseio.com",
  projectId: "invoiceme-ace6b",
  storageBucket: "invoiceme-ace6b.appspot.com",
  messagingSenderId: "775232613637",
  appId: "1:775232613637:web:765d580f7804ead0989b6c"
};

const Firebase = firebase.initializeApp(config);

const db = firebase.firestore();

db.enablePersistence().catch(({ code, message }) => {
  if (code === "failed-precondition") {
    console.log(message);
  } else if (code === "unimplemented") {
    console.log(message);
  }
});

export default Firebase;
