import React, { lazy, Suspense } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'firebase/auth';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import firebase from './firebase';
import FirebaseContext from './FirebaseContext';

import Loading from './Components/Loading';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

function App() {
  const [user] = useAuthState(firebase.auth());

  return user ? (
    <Suspense fallback={<Loading />}>
      <GlobalStylesWrapper>
        <FirebaseContext.Provider value={firebase}>
          <Router>
            <AuthenticatedApp />
          </Router>
        </FirebaseContext.Provider>
      </GlobalStylesWrapper>
    </Suspense>
  ) : (
    <Suspense fallback={<Loading />}>
      <GlobalStylesWrapper>
        <FirebaseContext.Provider value={firebase}>
          <Router>
            <UnauthenticatedApp />
          </Router>
        </FirebaseContext.Provider>
      </GlobalStylesWrapper>
    </Suspense>
  );
}

const GlobalStylesWrapper = styled.div`
  font-family: sans-serif;

  .print {
    font-family: sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  button,
  input[type='submit'] {
    cursor: pointer;
  }
`;

ReactDOM.render(<App />, document.getElementById('root'));
